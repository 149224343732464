import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AdminTable from '../../components/adminsitration/admintables/AdminTable';
import { Settings } from "feather-icons-react/build/IconComponents";
import BranchSetting from '../../components/adminsitration/BranchSetting';
import { fetchBranchSettings } from '../../redux/settingbyBranch';
import { fetchSection, fetchStateList, fetchType } from '../../redux/userSetting';
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
  Send
  
} from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { setheadertoggle } from '../../redux/toggle';

const Branch = () => {
  const { branchlist, branch } = useSelector((state) => state.branchlist);
  const {BranchByUser} = useSelector((state) => state.userBranch);
  const {specialpermission} = useSelector((state) => state.amc);
  const [specialpermit, setspecialpermit] = useState();

  useEffect(() => {
    dispatch(fetchType())
    dispatch(fetchStateList())
    dispatch(fetchSection(branch.guid))
      setspecialpermit(specialpermission?.userSpecialPermissionList?.find(e=> e.specialPermission.name =="Branch Settings"))
  }, [])
  
    
  // useEffect(() => {
  // }, [])
  const [searchText, setSearchText] = useState('');
  const [branchId, setbranchId] = useState();
  const dispatch = useDispatch()
  console.log(BranchByUser);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // Filtered list based on search text
const modifiedList = BranchByUser?.filter((value) =>
  value?.branch?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value?.branch?.company?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value?.branch?.address2?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value?.branch?.phone?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value?.branch?.email?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value?.branch?.website?.toLowerCase().includes(searchText?.toLowerCase())
);
console.log(modifiedList);

// Define columns for the table
const columns = [
  {
      title: "",
      dataIndex: "branch",
      render: (_, record) => (
          <div key={record.id}>
              <input
                  type="checkbox"
                  onChange={() => handleSelectBranch(record.branch.guid)}
                  checked={record.branch.guid === branchId}
              />
          </div>
      )
  },
  {
      title: "Name",
      dataIndex: "branch",
      render: (_, record) => record.branch?.name || ""
  },
  {
      title: "Company",
      dataIndex: "branch",
      render: (_, record) => record.branch?.company?.name || ""
  },
  {
      title: "Address 1",
      dataIndex: "branch",
      render: (_, record) => record.branch?.address1 || ""
  },
  {
      title: "Address 2",
      dataIndex: "branch",
      render: (_, record) => record.branch?.address2 || ""
  },
  {
      title: "Phone",
      dataIndex: "branch",
      render: (_, record) => record.branch?.phone || ""
  },
  {
      title: "Email",
      dataIndex: "branch",
      render: (_, record) => record.branch?.email || ""
  }
];


  const handleSelectBranch = (id) => {
    if (branchId == id) {
      setbranchId()
    } else {

      setbranchId(id)
    }
  }
  const handleGetSettings = (id) => {
    dispatch(fetchBranchSettings(id))


  }
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const {togglehead} = useSelector((state) => state.headerToggle);
    
  const handletogglehead=()=>{
    dispatch(setheadertoggle(!togglehead))
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Branch</h4>

              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              {specialpermit?.userRole.id !==0 &&
              <div className="page-btn">
                <button
                  disabled={branchId ? false : true}
                  className="btn btn-added"
                  data-bs-toggle="modal"
                  data-bs-target="#branch-setting"
                  onClick={() => handleGetSettings(branchId)}

                >
                  <Settings className="me-2" />
                  Setting
                </button>
              </div>
              }

            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line
                          x1={21}
                          y1={21}
                          x2="16.65"
                          y2="16.65"
                        />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>

              </div>

              <div className="table-responsive product-list">
                <AdminTable columns={columns} dataSource={modifiedList} handleSelect={handleSelectBranch} />
              </div>
            </div>
          </div>

        </div>
      </div>
      <BranchSetting />
    </div>
  );
}

export default Branch
