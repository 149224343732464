import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccountledger } from "../services/AccountApiServices";

export const fetchledger = createAsyncThunk("filters/fetchledger", async () => {
  const data = await getAccountledger();
  if (Array.isArray(data)) {
    return data;
  } else {
    return null;
  }
});

const listLedger = createSlice({
  name: "Ledger",
  initialState: {
    ledgerList: [],
    formatedLedger: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchledger.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchledger.fulfilled, (state, action) => {
        state.ledgerList = action.payload;
        state.formatedLedger = action.payload?.map((obj) => ({
          value: obj.guid,
          label: obj.name,
        }));
        state.loading = false;
      })
      .addCase(fetchledger.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default listLedger.reducer;
