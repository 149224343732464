import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import Select from "react-select";
import { Calendar } from "feather-icons-react/build/IconComponents";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { useSelector } from "react-redux";
import {
  addEditSales,
  getSalesByTransType,
} from "../../../services/SalesApiServices";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import CustomerModal from "../../master/modal/CustomerModal";
import { Journalentry } from "../../../core/json/JournalEntry";
import { journalDetails } from "../../../core/json/JournalDetails";
import { FinancialYear } from "../../../core/json/FinancialYear";
import {
  getJournal,
  savejournalentry,
} from "../../../services/AccountApiServices";

const JournalEntryModal = ({ mode, data, handleClose, handleRefresh }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deliveryDate: dayjs().format("YYYY-MM-DD"),
      invdate: dayjs().format("YYYY-MM-DD"),
      addition: 0,
      subtraction: 0,
      round: 0,
    },
    mode: "onBlur",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [isRowAdded, setIsRowAdded] = useState(false);
  // const [totalDebit, setTotalDebit] = useState(0);
  // const [totalCredit, setTotalCredit] = useState(0);
  const { userId } = useSelector((state) => state.userauth);
  const { branch } = useSelector((state) => state.branchlist);
  const { formatedLedger } = useSelector((state) => state.ledger);
  const { companyInfolist } = useSelector((state) => state.companyInfo);
  // Fetch sales quotations data
  const handleEntryNo = async () => {
    try {
      const response = await getJournal(branch);
      const receiptData = Array.isArray(response.data) ? response.data : [];
      // Find the maximum number from the existing entries
      const maxEntryNumber =
        receiptData?.length > 0
          ? receiptData
              ?.map((data) => parseInt(data.no))
              ?.reduce((max, num) => Math.max(max, num), 0) // Find the maximum number
          : 0;
      let counter = maxEntryNumber + 1;
      setValue(`no`, counter);
      counter++;
    } catch (error) {
      console.error("Failed to fetch journal entries", error);
    }
  };

  function resetmodal() {
    console.log("data", data);
    if (mode === "edit" && data) {
      console.log("data", data);
      setValue("remark", data.narration);
      setValue("no", data.no);
      setValue("date", dayjs(data.date) || dayjs());
    }
    setValue(
      "items",
      data?.journalDetails?.map((detail, i) => ({
        ledger: detail.ledgerID || "Unknown Ledger",
        debit: detail.debit || 0.0,
        credit: detail.credit || 0.0,
        narration: detail.narration,
      }))
    );
  }
  console.log(getValues().items);
  useEffect(() => {
    resetmodal();
  }, [data, append, remove]);

  useEffect(() => {
    const fetchData = async () => {
      if (mode === "add") {
        handleEntryNo();
        if (!isRowAdded && fields?.length === 0) {
          handleAddRow();
          setIsRowAdded(true);
        }
      }
    };

    fetchData();
  }, []);

  const handleAddRow = () => {
    append({
      debit: "0",
      credit: "0",
      narration: "",
      ledger: "",
    });
  };
  const handleFormSubmit = async (formData) => {
    if (totalCredit == totalDebit) {
      const objjournal = new Journalentry();
      const objfinance = new FinancialYear();
      objjournal.date = dayjs(formData.invdate).format("YYYY-MM-DD");
      objjournal.no = formData.no;
      objjournal.narration = formData.remark;
      objjournal.branch = branch;
      objjournal.financialYear = objfinance;
      objjournal.company = companyInfolist[0];
      objjournal.createdUser = userId.id;
      objjournal.updatedUser = userId.id;

      for (const row of formData.items) {
        const objdetail = new journalDetails();

        objdetail.ledgerID = row.ledger;
        objdetail.credit = Number(row.credit);
        objdetail.debit = Number(row.debit);
        objdetail.narration = row.narration;

        objjournal.journalDetails.push(objdetail);
      }

      try {
        const response = await savejournalentry(objjournal);
        if (response) {
          toast.success("success");
          handleClose();
          handleRefresh();
        }
      } catch (error) {
        toast.error(error?.response?.data?.Message || "something went wrong");
        console.error(
          "Error handling category:",
          error?.response?.data?.Message
        );
      }
    } else {
      toast.error("Credit and Debit should be tie");
    }
  };
  //Calculations
  const totalDebit = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].debit`) || 0),
    0
  );
  const totalCredit = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].credit`) || 0),
    0
  );
  const balanceClass =
    totalDebit == 0 && totalCredit == 0
      ? "text-center border-2" // Set to black if both are zero
      : totalDebit === totalCredit
      ? "text-center text-success border-2" // Set to green if they match and are not zero
      : "text-center text-danger border-2";
  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit Journal Entry"
                        : "Add Journal Entry"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                      <div className="row">
                        {/* row1 */}
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Entry No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("no")}
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>
                              Date <span className="text-danger">*</span>
                            </label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="invdate"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    } // Update on change
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className=" col-12 ">
                          <div className="input-blocks">
                            <label>Remark</label>
                            <div className="input-groupicon select-code">
                              <input
                                type="text"
                                className="form-control p-2"
                                {...register(`remark`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive  no-pagination table-container">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="sticky-header">
                                Ledger
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Debit<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Credit<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">Narration</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields?.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].ledger`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={formatedLedger}
                                          value={formatedLedger?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                          }}
                                          styles={{
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                              position: "relative",
                                            }),
                                          }}
                                          required
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].debit`, {
                                        required: "Debit is required",
                                        validate: (value) =>
                                          (!isNaN(value) && value > 0) ||
                                          "Debit must be a positive number",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.debit && (
                                        <span className="text-danger small">
                                          {errors.items[index].debit.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].credit`, {
                                        required: "Credit is required",
                                        validate: (value) =>
                                          (!isNaN(value) && value > 0) ||
                                          "Credit must be a positive number",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.credit && (
                                        <span className="text-danger small">
                                          {errors.items[index].credit.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].narration`)}
                                    />
                                  </td>
                                  <td>
                                    <Link
                                      className="confirm-text p-2"
                                      onClick={() => remove(index)}
                                    >
                                      <i
                                        data-feather="trash-2"
                                        className="feather-trash-2"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td></td>
                              <td className={balanceClass}>
                                <b>{totalDebit}</b>
                              </td>
                              <td className={balanceClass}>
                                <b>{totalCredit}</b>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-2 ps-0 ">
                        <button
                          className=" btn btn-dark add-icon tab  mt-3 "
                          type="button"
                          onClick={handleAddRow}
                        >
                          <PlusCircle className="feather-plus-circles" />
                        </button>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 text-end">
                          <button
                            type="button"
                            className="btn btn-cancel add-cancel me-3"
                            onClick={resetmodal}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-submit add-sale"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JournalEntryModal;
