import { DomainBase } from "./DomainBase";

export class OpeningStockMaster extends DomainBase {
  constructor() {
    super();
    this.entryNo = "";
    this.date = new Date();
    this.referenceNo = ""; //check
    this.branch = null;
    this.remarks = "";
    this.financialYear = null;
    this.openingStockDetail = [];
  }
}
