import { DomainBase } from "./DomainBase";
import { FinancialYear } from "./FinancialYear";
import { Company } from "./Company";
import { Branch } from "./Branch";
import { Currency } from "./Currency";

export class PaymentEntry extends DomainBase {
  constructor() {
    super();
    this.No = 0;
    this.Date = new Date();
    this.LedgerID = "";
    this.ledgerName = "";
    this.Narration = "";
    this.IsCheque = false;
    this.Currency = new Currency();
    this.CurrencyRate = 0;
    this.Branch = new Branch();
    this.FinancialYear = new FinancialYear();
    this.Company = new Company();
    this.paymentDetails = [];
    this.totalAmt = 0.0;
  }
}
