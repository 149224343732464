


import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChevronUp, RotateCcw } from "feather-icons-react/build/IconComponents";
import { useForm, Controller } from "react-hook-form";
import { Select } from "antd";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
    renderTooltip,
    exportExcel,
    exportPDF,
    HandlePrint,
} from "../../redux/helper.js";

const SalesBillWiseReceipt = () => {
    const { register, handleSubmit, control } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const onSubmit = (data) => {
        console.log(data);
        // Handle form submission logic here
    };

    const onPDFExport = () => exportPDF("Account Type");
    const onExcelExport = () => exportExcel("Account Type");

    return (
        <div className="modal fade" id="add-billwise">
            <div className="page-wrapper card p-4 shadow-sm">
       
            <div className="content">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content p-3">
                        <div className="modal-header border-0 ">
                            <h4 className="modal-title">Add BillWise Receipt</h4>
                            <button type="button" className="close"  >
                    <span aria-hidden="true">×</span>
                  </button>
                        </div>
                        <div className="modal-body custom-modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="input-blocks">
                                            <label>No <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control w-50" {...register("name", { required: true })} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <div className="input-blocks">
                                            <label>Customer</label>
                                            <select {...register("customer", { required: "Customer is required" })} className="form-control w-50">
                                                <option value="">Select customer</option>
                                                {/* Add customer options here */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <div className="input-blocks">
                                            <label>Date</label>
                                            <input type="date" className="form-control w-50" {...register("date")} />
                                        </div>
                                    </div>
                                  
                                    <div className="col-lg-12 mb-3">
                                        <div className="input-blocks">
                                            <label>Cash/Bank <span className="text-danger">*</span></label>
                                            <select {...register("ledger", { required: "Ledger is required" })} className="form-control w-50">
                                                <option value="">Select </option>
                                                {/* Add ledger options here */}
                                            </select>
                                        </div>
                                    </div>
                                   
                                    <div className="col-lg-12 mb-3">
                                        <div className="input-blocks">
                                            <label>Amount</label>
                                            <input type="number" className="form-control w-50" {...register("amount")} />
                                            <button className="btn float-left btn-submit m-2">fill</button>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="table-responsive no-pagination table-container mb-4">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Bill Type</th>
                                                <th>Bill No</th>
                                                <th>Date</th>
                                                <th>Bill Amount</th>
                                                <th>Paid</th>
                                                <th>Balance</th>
                                                <th>Paying</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* Example: map through items */}
                                            {[].map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Controller
                                                            name={`items[${index}].ledger`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select {...field} classNamePrefix="react-select" />
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            {...register(`items[${index}].billNo`)}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            {...register(`items[${index}].date`)}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            {...register(`items[${index}].billAmount`)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            {...register(`items[${index}].paid`)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            {...register(`items[${index}].paying`)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            {...register(`items[${index}].discount`)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            {...register(`items[${index}].balance`)}
                                                            disabled
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-lg-12 mb-3">
                                    <div className="input-blocks">
                                        <label>Narration</label>
                                        <input type="text" className="form-control w-50" {...register("narration")} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default SalesBillWiseReceipt;

