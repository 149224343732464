import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  RotateCcw,
  ChevronUp,
  PlusCircle,
  RefreshCw,
} from "feather-icons-react/build/IconComponents";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { Pagination } from "antd";
import {
  deleteSupplier,
  getSupplierList,
} from "../../services/MasterApiServices";
import SupplierModal from "../../components/master/modal/SupplierModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import { exportMasterExcel, exportMasterPDF, printMasterReport } from "../../core/utils/MasterReportUtils";

function Supplier() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [SupplierData, setSupplierData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const { togglehead } = useSelector((state) => state.headerToggle);
  const {menuItems} = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  useEffect(() => {
    handleSupplierList();
    setpermit(menuItems?.menuPermissions?.find(e=> e.menu.name =="Supplier"))
  }, []);
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = Array.isArray(SupplierData)
    ? SupplierData?.filter((value) =>
        value.name?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];
  const handleSupplierList = async () => {
    try {
      const response = await getSupplierList();

      if (Array.isArray(response)) {
        setSupplierData(response);
      } else {
        setSupplierData([]); // If response is not an array, reset to an empty array
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "something went wrong");
      setSupplierData([]);
    }
  };
  const handleEditClick = async (guid) => {
    try {
      // const response = await getPromotionList({guid,branch});
      const SingleGroup = getSupplierByGuid(guid);

      setUpdateData(SingleGroup); // Set the data for editing
      console.log(SingleGroup);

      setModalMode("edit"); // Set the modal mode to 'edit'
      setModalOpen(true); // Open the modal
    } catch (error) {
      console.error(
        "Error fetching promotion GUID:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };
  // Function to get a single object from filtering a list of group objects
  function getSupplierByGuid(guid) {
    const filteredData = Array.isArray(SupplierData)
      ? SupplierData.filter((group) => group.guid === guid)
      : [];
    return filteredData[0];
  }

  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      console.log(result);
      if (result.isConfirmed) {
        try {
          const response = await deleteSupplier(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The supplier has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handleSupplierList(); // Refresh the list
              // setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting Reasontype:",
            error?.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "code",
      dataIndex: "code",
      sorter: (a, b) => a.Name?.length - b.Name?.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Adress1",
      dataIndex: "address1",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Adress2",
      dataIndex: "address2",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj.id)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : [])
  ];

  const MySwal = withReactContent(Swal);
  const getActiveTabData = () => {
    let fieldMapping = {};
  
    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };
  
    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);
    
    // Return the filtered data, columns, and field mapping
    return { data: modifiedList, columns: columns, fieldMapping };
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Supplier</h4>
                <h6>Manage Supplier here</h6>
              </div>
            </div>
            <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                <Link onClick={() => exportMasterPDF(getActiveTabData,"Supplier")}>
                  <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("Excel")}>
                <Link
                  onClick={() => exportMasterExcel(getActiveTabData,"Supplier")}
                >
                  <ImageWithBasePath
                    src="assets/img/icons/excel.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("Print")}>
                <Link
                 onClick={() => printMasterReport(getActiveTabData,"Supplier")}
                >
                  <i data-feather="printer" className="feather-printer" />
                </Link>
              </OverlayTrigger>
            </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleSupplierList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <ul className="table-top-head"></ul>
            <div className="d-flex purchase-pg-btn">
            {permit?.add && 
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>}
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}

              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Pagination />
      {modalOpen && (
        <SupplierModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handleSupplierList(); // Refresh the list
            // Clear the search text
          }}
        />
      )}
    </div>
  );
}

export default Supplier;
