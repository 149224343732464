export class FinancialYear {
  constructor() {
    this.id = 1;
    this.guid = "00000000-0000-0000-0000-000000000000";
    this.version = 0;
    this.isDeleted = false;
    this.createdUser = "";
    this.createdDate = new Date();
    this.updatedUser = "";
    this.updatedDate = new Date();
  }
}
