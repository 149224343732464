import React, { useEffect, useRef, useState } from "react";
import {
  getwebmenupermission,
  savewebmenupermission,
} from "../../../services/AdminApiServices";
import { useForm } from "react-hook-form";
import { WebUserMenuPermission } from "../../../core/json/WebUserMenuPermission";
import toast from "react-hot-toast";
import { Checkbox } from "antd";
import { fetchMenuServices } from "../../../redux/menuServices";
import { useDispatch, useSelector } from "react-redux";
import { fetchmenuPermissions } from "../../../redux/amcstatus";
import { MenuPermissions } from "../../../core/json/MenuPermissions";
import { Menu } from "../../../core/json/Menu";

const WebMenuPermission = ({ data, RoleId }) => {
  console.log(data);

  const { register, handleSubmit, setValue, getValues, watch, reset } = useForm(
    {
      mode: "onChange",
      reValidateMode: "onChange",
    }
  );
  const { userId } = useSelector((state) => state.userauth);
  const { UserServicelist } = useSelector((state) => state.userservice);
  const [parent, setparent] = useState([]);
  const [PermissionData, setPermissionData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      handleGetPermissions();
    }
  }, [data, RoleId]);
  console.log(userId);

  const handleGetPermissions = async () => {
    try {
      const response = await getwebmenupermission(data);
      setPermissionData(response.menuPermissions);
      const parents = response?.menuPermissions?.filter((item) =>
        response?.menuPermissions?.some(
          (child) => child.menu.parentID === item.menu.id
        )
      );
      console.log(PermissionData, "response.menuPermissions");
      console.log(parents, "parents");
      setparent(parents);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const closeButtonRef = useRef();
  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  const handlePermission = async () => {
    const webObj = new WebUserMenuPermission();
    webObj.UserRole = data;
    webObj.MenuPermissions = PermissionData;

    try {
      const response = await savewebmenupermission(webObj);
      if (response == true) {
        toast.success("success");
        dispatch(fetchMenuServices({ id: userId.id }));
        dispatch(fetchmenuPermissions(UserServicelist.userRole));
        closemodal();
        handleGetPermissions();
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error fetching states:", error);
    }
  };
  const handleview = (val, menuId) => {
    setPermissionData((prevData) => {
      // Create a new array with the updated item
      return prevData.map(
        (item) =>
          item.menu.id === menuId
            ? { ...item, view: val } // Update the 'view' property
            : item // Leave other items unchanged
      );
    });
  };
  const handleadd = (val, menuId) => {
    setPermissionData((prevData) => {
      // Create a new array with the updated item
      return prevData.map(
        (item) =>
          item.menu.id === menuId
            ? { ...item, add: val } // Update the 'view' property
            : item // Leave other items unchanged
      );
    });
  };
  const handleedit = (val, menuId) => {
    setPermissionData((prevData) => {
      // Create a new array with the updated item
      return prevData.map(
        (item) =>
          item.menu.id === menuId
            ? { ...item, edit: val } // Update the 'view' property
            : item // Leave other items unchanged
      );
    });
  };
  const handledelete = (val, menuId) => {
    setPermissionData((prevData) => {
      // Create a new array with the updated item
      return prevData.map(
        (item) =>
          item.menu.id === menuId
            ? { ...item, deletion: val } // Update the 'view' property
            : item // Leave other items unchanged
      );
    });
  };

  return (
    <div>
      <div className="modal fade" id="web-menu">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Web Menu Permissions</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="form_body">
                    <div className="accordion" id="accordionExample">
                      {parent?.map((e, i) => (
                        <div className="accordion-item" key={i}>
                          <h2
                            className="accordion-header"
                            id={`heading${i}${RoleId}`}
                          >
                            <div
                              className="accordion-button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${i}${RoleId}`}
                              aria-expanded="true"
                              aria-controls={`collapse${i}${RoleId}`}
                            >
                              {e.menu.name}
                            </div>
                          </h2>
                          <div
                            id={`collapse${i}${RoleId}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading${i}${RoleId}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              {PermissionData?.filter(
                                (ev) => ev.menu.parentID === e.menu.id
                              ) // Filter permissions based on parent ID
                                .map(
                                  (
                                    obj,
                                    index // Map through filtered permissions
                                  ) => (
                                    <div className="row" key={obj.menu.id}>
                                      <div className="col-8">
                                        {obj.menu.name}
                                      </div>
                                      <div className="col-4">
                                        {/* View Checkbox */}
                                        <input
                                          type="checkbox"
                                          checked={obj.view}
                                          onChange={(e) => {
                                            handleview(
                                              e.target.checked,
                                              obj.menu.id
                                            );
                                          }}
                                        />
                                        <label className="m-1">View</label>
                                        &nbsp;&nbsp;
                                        {/* Add Checkbox */}
                                        <input
                                          type="checkbox"
                                          checked={obj.view}
                                          onChange={(e) => {
                                            handleadd(
                                              e.target.checked,
                                              obj.menu.id
                                            );
                                          }}
                                        />
                                        <label className="m-1">Add</label>
                                        &nbsp;&nbsp;
                                        {/* Edit Checkbox */}
                                        <input
                                          type="checkbox"
                                          checked={obj.edit}
                                          onChange={(e) => {
                                            handleedit(
                                              e.target.checked,
                                              obj.menu.id
                                            );
                                          }}
                                        />
                                        <label className="m-1">Edit</label>
                                        &nbsp;&nbsp;
                                        {/* Delete Checkbox */}
                                        <input
                                          type="checkbox"
                                          checked={obj.deletion}
                                          onChange={(e) => {
                                            handledelete(
                                              e.target.checked,
                                              obj.menu.id
                                            );
                                          }}
                                        />
                                        <label className="m-1">Delete</label>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-submit"
                        onClick={handlePermission}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebMenuPermission;
