import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
  Sliders,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import { fetchUserRole } from "../../redux/userSetting";
import AdduserRole from "../../components/adminsitration/userroles/AdduserRole";
import AdminProductTable from "../../components/adminsitration/admintables/AdminProductTable";
import WebMenuPermission from "../../components/adminsitration/userroles/WebMenuPermission";
import { deleteUserRole } from "../../services/AdminApiServices";
import WebSpecialPermission from "../../components/adminsitration/userroles/WebSpecialPermission";
import WindowMenuPermission from "../../components/adminsitration/userroles/WindowMenuPermission";
import WindowSpecialPermission from "../../components/adminsitration/userroles/WindowSpecialPermission";

const UserRole = () => {
  const [searchText, setSearchText] = useState("");
  const [RoleId, setRoleId] = useState();
  const [userRoleList, setuserRolesList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [updateData, setUpdateData] = useState([]);
  const [permitData, setpermitData] = useState([]);
  const [levels, setlevels] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const dispatch = useDispatch();
  const { userRoles } = useSelector((state) => state.usersetting);
  const { menuItems, specialpermission } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();

  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "User Role")
    );
  }, []);
  useEffect(() => {
    handleLevel();
    setuserRolesList(userRoles);
  }, [userRoles]);
  const { togglehead } = useSelector((state) => state.headerToggle);

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const modifiedList = userRoles?.filter((value) =>
      value.name?.toLowerCase().includes(e.target.value?.toLowerCase())
    );
    setuserRolesList(modifiedList);
  };
  const handleactive = (e) => {
    console.log(e.value);
    const modifiedList = userRoles?.filter((value) => value.active == e.value);
    setuserRolesList(modifiedList);
  };

  const handleEditClick = async (id, level) => {
    setModalMode("edit"); // Set the modal mode to 'edit'
    setModalOpen(true); // Open the modal
    handleLevel();
    const data = userRoles?.find((e) => id == e.guid);
    setUpdateData(data);
    // try {
    //   const response = await getuserRolebyId({id});
    //   setUpdateData(response.data); // Set the data for editing
    // } catch (error) {
    //   console.error("Error Fetching Account Type !", error);
    // }
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      render: (_, record) => (
        <div key={record.id}>
          <input
            type="checkbox"
            onChange={() => handleSelectRole(record)}
            checked={record.guid == RoleId}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    {
      title: "Active",
      dataIndex: "active",

      render: (_, record) => (
        <div
          className={`badges ${
            record.active == true ? "status-badge" : "badge-bgdanger"
          }`}
        >
          {record.active == true ? "Active" : "Inactive"}
        </div>
      ),
    },

    {
      title: "Level",
      dataIndex: "level",
      sorter: (a, b) => a.level - b.level,
    },

    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid, obj.level)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj.id)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const handleSelectRole = (data) => {
    if (RoleId == data.guid) {
      setRoleId();
    } else {
      setpermitData(data);
      setRoleId(data.guid);
    }
  };

  const handleLevel = () => {
    const newlevel = levels?.filter((level) =>
      userRoles?.every((role) => role.level !== level)
    );

    setlevels(newlevel);
  };

  const activejson = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const MySwal = withReactContent(Swal);

  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      console.log(result);
      if (result.isConfirmed) {
        try {
          const response = await deleteUserRole(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The User Role has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            });
            dispatch(fetchUserRole()); // Refresh the list
            // setSearchText(""); // Clear the search text
            handleLevel();
          } else {
            MySwal.fire({
              title: "Error!",
              text: response.Message,
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(error?.response?.data?.Message);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>User Role</h4>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => {
                      fetchUserRole();
                      setuserRolesList(userRoles);
                      handleLevel();
                    }}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <button
                  className="btn btn-added"
                  disabled={!RoleId}
                  data-bs-toggle="modal"
                  data-bs-target="#web-menu"
                >
                  Web menu permission
                </button>
              </div>

              <div className="page-btn">
                <button
                  className="btn btn-added"
                  disabled={!RoleId}
                  data-bs-toggle="modal"
                  data-bs-target="#web-special-menu"
                >
                  Web special permission
                </button>
              </div>

              <div className="page-btn">
                <button
                  className="btn btn-added"
                  disabled={!RoleId}
                  data-bs-toggle="modal"
                  data-bs-target="#window-menu"
                >
                  Windows menu permission
                </button>
              </div>

              <div className="page-btn">
                <button
                  className="btn btn-added"
                  disabled={!RoleId}
                  data-bs-toggle="modal"
                  data-bs-target="#window-special-menu"
                >
                  Windows special permission
                </button>
              </div>
              {permit?.add && (
                <div className="page-btn">
                  <Link
                    to="#"
                    className="btn btn-added"
                    onClick={() => {
                      setModalMode("add"); // Set the modal mode to 'add'
                      setModalOpen(true); // Open the modal
                    }}
                  >
                    <PlusCircle className="me-2" />
                    New
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search by Name"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="img-select"
                    classNamePrefix="react-select"
                    options={activejson}
                    onChange={handleactive}
                    placeholder="Active"
                  />
                </div>
              </div>
              <div className="table-responsive product-list">
                <AdminProductTable
                  columns={columns}
                  dataSource={userRoleList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {RoleId && <WebMenuPermission data={permitData} RoleId={RoleId} />}
      {RoleId && <WebSpecialPermission data={permitData} />}
      {RoleId && <WindowMenuPermission data={permitData} />}
      {RoleId && <WindowSpecialPermission data={permitData} />}
      {modalOpen && (
        <AdduserRole
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          levels={levels}
        />
      )}
    </div>
  );
};

export default UserRole;
