import { DomainBase } from "./DomainBase";

 export class AccLedger extends DomainBase { 
     constructor() { 
      super();
           this.Name = '';  
             this.GroupID = null;   
              this.IsDebit = false;  
                this.Amount = 0;  
                  this.CompanyID = null; 
                     this.IsFixed = false; 
                     }}