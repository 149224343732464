import React, { useState } from "react";
import { Table } from "antd";
import isEmpty from "lodash/isEmpty";

const Datatable = ({
  props,
  columns,
  dataSource,
  footerTotals,
  branches = [],
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table
      key={props}
      className="table datanew dataTable no-footer"
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.id}
      pagination={{
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      summary={() => {
        return (
          <Table.Summary>
            {!isEmpty(footerTotals) && (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                {branches.map((branch, index) => {
                  const netQuantity = footerTotals.netQuantity[branch] || 0;
                  const netTotal = footerTotals.netTotal[branch] || 0;

                  return netQuantity > 0 || netTotal > 0 ? ( // Only render if either value is greater than 0
                    <React.Fragment key={branch}>
                      <Table.Summary.Cell
                        key={`${branch}_quantity`}
                        index={index * 2 + 1}
                      >
                        {netQuantity.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        key={`${branch}_total`}
                        index={index * 2 + 2}
                      >
                        {netTotal.toFixed(2)}
                      </Table.Summary.Cell>
                    </React.Fragment>
                  ) : null; // Do not render anything if both values are 0
                })}
                <Table.Summary.Cell index={branches.length * 2 + 1} colSpan={1}>
                  {footerTotals.itemQuantity !== undefined
                    ? footerTotals.itemQuantity.toFixed(2)
                    : "0.00"}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={branches.length * 2 + 2} colSpan={1}>
                  {footerTotals.itemTotal !== undefined
                    ? footerTotals.itemTotal.toFixed(2)
                    : "0.00"}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          </Table.Summary>
        );
      }}
    />
  );
};

export default Datatable;
