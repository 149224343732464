import React, { useEffect, useRef, useState } from "react";
import {
  getwebmenuspecialpermission,
  savewebspecialpermission,
} from "../../../services/AdminApiServices";
import { UserRole } from "../../../core/json/UserRole";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { UserSpecialPermission } from "../../../core/json/UserSpecialPermission";
import { Checkbox } from "antd";
import { fetchspecialpermission } from "../../../redux/amcstatus";
import { useDispatch, useSelector } from "react-redux";

const WebSpecialPermission = ({ data }) => {
  const { register, handleSubmit, setValue, getValues, watch } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { UserServicelist } = useSelector((state) => state.userservice);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      handleGetPermissions();
    }
  }, [data]);

  const handleGetPermissions = async () => {
    try {
      const response = await getwebmenuspecialpermission(data);
      console.log(response);
      const newlist = response?.userSpecialPermissionList?.map((e) => ({
        name: e.specialPermission.name,
        active: e.specialPermission.active,
      }));
      console.log(newlist);

      setValue("special", response?.userSpecialPermissionList);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  const handlePermission = async () => {
    const permissions = watch().special?.filter((e) => e?.userRole?.id !== 0);
    // console.log(permissions);

    const webObj = new UserSpecialPermission();
    webObj.UserRole = data;
    webObj.userSpecialPermissionList = permissions;

    try {
      const response = await savewebspecialpermission(webObj);
      if (response == true) {
        toast.success("success");
        closemodal();
        dispatch(fetchspecialpermission(UserServicelist.userRole));
        handleGetPermissions();
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const [previousValues, setPreviousValues] = useState({}); // Store previous values per index

  const handleCheckboxChange = (e, index) => {
    const currentValue = watch(`special[${index}].userRole.id`);

    if (e.target.checked) {
      // Restore the old value when checked
      setValue(
        `special[${index}].userRole.id`,
        previousValues[index] || currentValue || 1
      );
    } else {
      // Store the current value before setting to 0 when unchecked
      setPreviousValues((prev) => ({
        ...prev,
        [index]: currentValue !== 0 ? currentValue : previousValues[index] || 1,
      }));
      setValue(`special[${index}].userRole.id`, 0);
    }
  };
  const specialPermissions = watch().special;
  return (
    <div>
      <div className="modal fade" id="web-special-menu">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Web Special Permissions</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handlePermission)}>
                    <div className="form_body">
                      {Array.isArray(specialPermissions) &&
                      specialPermissions.length > 0 ? (
                        specialPermissions.map((obj, index) => (
                          <div key={index}>
                            <input
                              type="checkbox"
                              checked={
                                watch(`special[${index}].userRole.id`) !== 0
                              }
                              onChange={(e) => handleCheckboxChange(e, index)}
                            />
                            <label className="m-1 mb-4">
                              {obj.specialPermission.name}
                            </label>
                          </div>
                        ))
                      ) : (
                        <p className="text-center">No Data</p>
                      )}
                    </div>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebSpecialPermission;
