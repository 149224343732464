import { DomainBase } from "./DomainBase";

export class BillPaymentEntry extends DomainBase {
    constructor() {
        super();  // Call the parent class constructor
        this.No = 0;
        this.Date = new Date();
        this.Supplier = null;
        this.LedgerID = '';
        this.Narration = '';
        this.Amount = 0;
        this.IsCheque = false;
        this.Currency = null;
        this.CurrencyRate = 0;
        this.Branch = null;
        this.FinancialYear = null;
        this.Company = null;
        this.Discount = 0;
        this.billPaymentDetail = [];
    }
}
