import React from 'react'
import FolderTree from "react-folder-tree";

const Test = () => {
  const treeState = {
    name: 'root [half checked and opened]',
    checked: 0.5,   // half check: some children are checked
    isOpen: true,   // this folder is opened, we can see it's children
    children: [
      { name: 'children 1 [not checked]', checked: 0 },
      {
        name: 'children 2 [half checked and not opened]',
        checked: 0.5,
        isOpen: false,
        children: [
          { name: 'children 2-1 [not checked]', checked: 0 },
          { name: 'children 2-2 [checked]', checked: 1 },
        ],
      },
    ],
  };
  return (
    <div>
      <FolderTree
    data={ treeState }
    initCheckedStatus='custom'  // default: 0 [unchecked]
    initOpenStatus='custom'     // default: 'open'
  />
    </div>
  )
}

export default Test
