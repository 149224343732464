import { DomainBase } from "./DomainBase";

export class journalDetails extends DomainBase {
    constructor() {
        super();
        this.ledgerID = 0;
        this.credit = '';
        this.debit = '';
        this.narration = '';
        
    }
}