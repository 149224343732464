import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { DatePicker } from "antd";
import Select from "react-select";
import { Calendar } from "feather-icons-react/build/IconComponents";
import { useSelector } from "react-redux";
import { InvSales } from "../../../core/json/invSales";

import {
  getbillwisepayment,
  getsupBill,
  savebillpayment,
} from "../../../services/AccountApiServices";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { BillPaymentEntry } from "../../../core/json/BillPaymentEntry";
import { FinancialYear } from "../../../core/json/FinancialYear";
import { BillPaymentDetail } from "../../../core/json/BillPaymentDetail";
import { Currency } from "../../../core/json/Currency";
import { fetchBranchSettings } from "../../../redux/settingbyBranch";
import { SupplierClass } from "../../../core/json/SupplierClass";

const Billreceiptmodal = ({ mode, data, handleClose, handleRefresh,bankorcash }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deliveryDate: dayjs().format("YYYY-MM-DD"),
      invdate: dayjs().format("YYYY-MM-DD"),
      isCheque: false,
      amount: 0,
    },
    mode: "onBlur",
  });
  const isCheque = watch("isCheque");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [isRowAdded, setIsRowAdded] = useState(false);
  const [decimalpoints, setDecimalPoints] = useState(2);
  const [supBill, setsupBill] = useState([]);

  const {formatedsupplierList} = useSelector((state) => state.usersetting);
  const { companyInfolist } = useSelector((state) => state.companyInfo);
  const { userId } = useSelector((state) => state.userauth);
  const { branch } = useSelector((state) => state.branchlist);
  const { branchsettings } = useSelector((state) => state.settings);
  useEffect(() => {
    dispatch(fetchBranchSettings(branch.guid));
  }, [branch]);
  function handleFill() {
    const amount = parseFloat(watch("amount", 0)) || 0;
    let remainingAmount = amount;

    fields.forEach((field, index) => {
      const balance = parseFloat(watch(`items[${index}].balance`, 0)) || 0;
      console.log(`Row ${index + 1} - Initial Amount:`, remainingAmount);
      console.log(`Row ${index + 1} - Initial Balance:`, balance);

      if (remainingAmount > 0) {
        const payingAmount = Math.min(remainingAmount, balance);
        setValue(`items[${index}].paying`, payingAmount.toFixed(decimalpoints));
        setValue(
          `items[${index}].balance`,
          (balance - payingAmount).toFixed(decimalpoints)
        );
        remainingAmount -= payingAmount;
      }
    });
  }

  // Get branch from redux
  // Fetch sales quotations data
  const handleEntryNo = async () => {
    try {
      const response = await getbillwisepayment(branch);
      const receiptData = response;
      // Find the maximum number from the existing entries
      const maxEntryNumber =
        receiptData?.length > 0
          ? receiptData
              ?.map((data) => parseInt(data.no))
              ?.reduce((max, num) => Math.max(max, num), 0) // Find the maximum number
          : 0;
      let counter = maxEntryNumber + 1;
      setValue(`no`, counter);
      counter++;
    } catch (error) {
      console.error("Failed to fetch purchase invoices", error);
    }
  };

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("no", data.no);
      setValue("paymentdate", data.date);
      setValue("supplier", data.supplier.guid);
      setValue("ledger", data.ledgerID);
      setValue("subtraction", data.ledgerID);
      setValue("isCheque", data.isCheque);
      // setValue("amount", data.amount);
      setValue("remark", data.narration);
      const detail = data?.billPaymentDetail?.map((e) => {
        const bal = (e.netTotal - (e.amount + e.discount)).toFixed(2); // Rounds balance to 2 decimal places

        // Return the transformed object
        return {
          masterID: e.masterID,
          purchaseMasterID: e.purchaseMasterID,
          purchaseReturnMasterID: e.purchaseReturnMasterID,
          billtype: e.transType,
          // billno: e.entryNumber,
          billno: e.purchaseMasterID,
          date: dayjs(e.entryDate).format("DD-MM-YYYY"),
          billamount: e.netTotal,
          paid: e.amount,
          discount: e.discount,
          invNo: e.invoiceNO,
          invdate: dayjs(e.entryDate).format("DD-MM-YYYY"), // Correct usage of dayjs
          balance: bal>0 ? bal : 0 ,
        };
      });
console.log(detail,data?.billPaymentDetail,"ssssssssssss");

      setValue("items", detail);
    }
   
  }
  console.log(getValues().items);
  useEffect(() => {
    resetmodal();
  }, [data, append, remove]);

  useEffect(() => {
    const fetchData = async () => {
      if (mode === "add") {
        handleEntryNo();
        if (!isRowAdded && fields?.length === 0) {
          setIsRowAdded(true);
        }
      }
    };

    fetchData();
  }, []);
  const handlesaveorupdate = async (formData) => {
    console.log(formData);
    console.log(branchsettings, "PrimaryCurrency");

    const objbillpayment = new BillPaymentEntry();
    const objfinance = new FinancialYear();
    const objcurrency = new Currency();
    const objsupplier = new SupplierClass();
    objcurrency.guid = branchsettings?.primaryCurrencyGUID;
    objbillpayment.guid = mode =='edit'? data.guid :'';
    objbillpayment.id = mode =='edit'? data.id : 0;
    objbillpayment.Currency = objcurrency;
    objbillpayment.date = dayjs(formData.invdate).format("YYYY-MM-DD");
    objbillpayment.No = formData.no;
    objsupplier.Guid = formData.supplier;
    objbillpayment.Supplier = objsupplier;
    objbillpayment.Narration = formData.remark;
    objbillpayment.Amount = formData.amount;
    objbillpayment.LedgerID = formData.ledger;
    objbillpayment.Branch = branch;
    objbillpayment.FinancialYear = objfinance;
    objbillpayment.Company = companyInfolist[0];
    objbillpayment.createdUser = userId.id;
    objbillpayment.updatedUser = userId.id;

    for (const row of formData.items) {
      const objdetail = new BillPaymentDetail();
      (objdetail.MasterID = row.masterID),
        (objdetail.PurchaseMasterID = row.purchaseMasterID),
        (objdetail.PurchaseReturnMasterID = row.purchaseReturnMasterID),
        (objdetail.Amount = parseFloat(row.paying));
      objdetail.Discount = parseFloat(row.discount);

      objbillpayment.billPaymentDetail.push(objdetail);
    }

    try {
      console.log(objbillpayment, "PAYLOAD");
      const response = await savebillpayment(objbillpayment);
      if (response.data == true) {
        // fields.forEach((_, index) => remove(index))
        toast.success("success");
        handleClose();
      } else {
        toast.success(response.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling category:", error?.response?.data?.Message);
    }
  }
  
  const handlebill = async (val) => {
    try {
      const response = await getsupBill(val, branch);
      console.log(response.data, "Response Data");

      if (Array.isArray(response.data)) {
        const data = response.data.map((e) => {
          const bal = (e.netTotal - (e.amount + e.discount)).toFixed(2); // Rounds balance to 2 decimal places

          // Return the transformed object
          return {
            masterID: e.masterID,
            purchaseMasterID: e.purchaseMasterID,
            purchaseReturnMasterID: e.purchaseReturnMasterID,
            billtype: e.transType,
            billno: e.entryNumber,
            date: dayjs(e.entryDate).format("DD-MM-YYYY"),
            billamount: e.netTotal,
            paid: e.amount,
            discount: e.discount,
            invNo: e.invoiceNO,
            invdate: dayjs(e.entryDate).format("DD-MM-YYYY"), // Correct usage of dayjs
            balance: bal,
          };
        });
        console.log(data, "Formatted Data");

        setValue("items", data);
        setsupBill(response.data);
      } else {
        setsupBill([]);
      }
    } catch (error) {
      console.error("Error fetching bill data:", error);
      setsupBill([]);
    }
  };
  const totalamount = fields?.reduce(
    (sum, _, index) =>
      sum + parseFloat(watch(`items[${index}].billamount`) || 0),
    0
  );
  const totalpaid = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].paid`) || 0),
    0
  );
  const totalpaying = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].paying`) || 0),
    0
  );
  const totaldiscount = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].discount`) || 0),
    0
  );
  const totalbalance = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].balance`) || 0),
    0
  );

  // const amount = watch().amount || 0;
  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit Bill Wise Payment"
                        : "Add Bill Wise Payment"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(handlesaveorupdate)}>
                      <div className="row">
                        {/* row1 */}
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Entry No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("no")}
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Date</label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="paymentdate"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    } // Update on change
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product">
                            <label>
                              Supplier<span className="text-danger">*</span>
                            </label>

                            <Controller
                              control={control}
                              name={`supplier`}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  classNamePrefix="react-select"
                                  options={formatedsupplierList}
                                  value={formatedsupplierList?.find(
                                    (customer) => customer?.value === value
                                  )}
                                  onChange={(selectedOption) => {
                                    onChange(selectedOption.value);
                                    handlebill(selectedOption.value);
                                  }}
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              Ledger<span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <Controller
                                control={control}
                                name={`ledger`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    classNamePrefix="react-select"
                                    options={bankorcash}
                                    value={bankorcash?.find(
                                      (bank) => bank?.value === value
                                    )}
                                    onChange={(selectedOption) =>
                                      onChange(selectedOption.value)
                                    }
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <div className="form-check form-switch mt-4">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                {...register("isCheque")}
                              />
                              <label className="form-check-label">
                                Cheque ?
                              </label>
                            </div>
                          </div>
                        </div>

                        {isCheque && (
                          <>
                            {["chequeNo", "accNo", "holdername"].map(
                              (field, index) => (
                                <div
                                  key={index}
                                  className="col-lg-4 col-sm-6 col-12"
                                >
                                  <div className="input-blocks">
                                    <label className="form-label">{`${
                                      field.charAt(0).toUpperCase() +
                                      field.slice(1).replace(/([A-Z])/g, " $1")
                                    }`}</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(field)}
                                      required
                                    />
                                  </div>
                                </div>
                              )
                            )}

                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks">
                                <label>Cheque Date</label>
                                <div className="input-groupicon calender-input">
                                  <Calendar className="info-img" />
                                  <Controller
                                    control={control}
                                    name="chequedate"
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <DatePicker
                                        className="datetimepicker"
                                        value={value ? dayjs(value) : dayjs()}
                                        format="DD-MM-YYYY"
                                        onChange={(date) =>
                                          onChange(
                                            date
                                              ? date.format("YYYY-MM-DD")
                                              : null
                                          )
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-sm-6 col-6">
                          <div className="input-blocks">
                            <label className="form-label">Amount</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("amount")}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks mt-1">
                            {/* <label className="form-label">Amount</label> */}
                            <button
                              className="btn btn-success mt-4 px-4"
                              onClick={handleFill}
                              type="button"
                            >
                              Fill
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive  no-pagination table-container">
                        <table className="table tablezindbill">
                          <thead>
                            <tr>
                              <th className="sticky-header" style={{width:"10px"}}> sl</th>
                              <th className="sticky-header">Bill Type</th>
                              <th className="sticky-header">Bill No.</th>
                              <th className="sticky-header">Date</th>
                              <th className="sticky-header">Bill Amount</th>
                              <th className="sticky-header">Paid</th>
                              <th className="sticky-header">Paying</th>
                              <th className="sticky-header">Discount</th>
                              <th className="sticky-header">Balance</th>
                              <th className="sticky-header">Sup Inv No.</th>
                              <th className="sticky-header">Sup Inv Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields?.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      {...register(`items[${index}].select`)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].billtype`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].billno`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].date`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      min={0}
                                      step="any"
                                      className="form-control"
                                      {...register(
                                        `items[${index}].billamount`
                                      )}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                       type="number"
                                       min={0}
                                       step="any"
                                      className="form-control"
                                      {...register(`items[${index}].paid`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                       type="number"
                                       min={0}
                                       step="any"
                                      className="form-control"
                                      {...register(`items[${index}].paying`)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                       type="number"
                                       min={0}
                                       step="any"
                                      className="form-control"
                                      {...register(`items[${index}].discount`)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                       type="number"
                                       min={0}
                                       step="any"
                                      className="form-control"
                                      {...register(`items[${index}].balance`)}
                                      // disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].invNo`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].invdate`)}
                                      disabled
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="text-center border-2">
                                <b>{totalamount || 0}</b>
                              </td>
                              <td className="text-center border-2">
                                <b>{totalpaid || 0}</b>
                              </td>
                              <td className="text-center border-2">
                                <b>{totalpaying || 0}</b>
                              </td>
                              <td className="text-center border-2">
                                <b>{totaldiscount || 0}</b>
                              </td>
                              <td className="text-center border-2">
                                <b>{totalbalance || 0}</b>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <div className="input-blocks">
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("remark")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-end">
                          <button
                            type="button"
                            className="btn btn-cancel add-cancel me-3"
                            onClick={resetmodal}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-submit add-sale"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};
export default Billreceiptmodal;
