import { DomainBase } from "./DomainBase";

export class Journalentry extends DomainBase {
  constructor() {
    super();
    this.no = 0;
    this.date = new Date();
    this.narration = "";
    this.financialYear = null;
    this.company = null;
    this.branch = null;
    this.journalDetails = [];
    this.voucherDetails = [];
  }
}
