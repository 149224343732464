
import React, { useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Archive,
  User,
  Calendar,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { Table } from "antd";
import { purchaseslist } from "../../../core/json/purchaselistdata";
import { setheadertoggle } from "../../../redux/toggle";
import { useDispatch, useSelector } from "react-redux";
import { getSalesReport, getSalesStatusReport } from "../../../services/ReportApiService";
import { DatatablePagingParameter, Filterclass } from "../../../core/json/DatatablePagingParameters";
import {
  exportPDF,
  exportExcel,
  HandlePrint,
  renderTooltip,
} from "../../../redux/helper";
import * as XLSX from 'xlsx';
import { Checkbox } from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { DatePicker } from "antd";
// import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { fetchCounter, fetchSection, fetchUserRole } from "../../../redux/userSetting";
import { fetchUser } from "../../../redux/userServices";
import { exportreportExcel, exportreportPDF, printreportReport } from "../../../core/utils/MainReportUtils";
import LoadingUtils from "../../../core/utils/LoadingUtils";

const CancelledSales = () => {
  const purchasedata = purchaseslist;
  const [searchText, setSearchText] = useState("");
  const [salesData, setsalesData] = useState();
  const [waiter,setwaiter]= useState()
  const [counterval,setcounterval]= useState()
  const [sectionval,setsectionval]= useState()
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [totalcount, settotalcount] = useState();
  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(20);
  const [allData, setallData] = useState();
  const dispatch = useDispatch()
  const { branch } = useSelector((state) => state.branchlist);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { userRoles, counterList, sectionList } = useSelector((state) => state.usersetting);
  const {UserList} = useSelector((state) => state.userservice);
  const tableRef = useRef(null);
  const filteredData = purchasedata?.filter((entry) => {
    return Object.keys(entry).some((key) => {
      return String(entry[key])
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  });
  useEffect(() => {
    handlegetsalesReport(currentPage,pageSize);
    handlegetsalesfullReport();
    dispatch(fetchUser())
    dispatch(fetchSection(branch.guid))
    dispatch(fetchCounter(branch.guid))
    
   
  }, [])

  const handlegetsalesReport = async (page,size) => {
    const DatatablePagingParameters = new DatatablePagingParameter();
    DatatablePagingParameters.PageNumber = page;
    DatatablePagingParameters.PageSize = size;
    const formattedfromDate = dayjs(fromDate).format('YYYY-MM-DD');
    const formattedtoDate = dayjs(toDate).format('YYYY-MM-DD');
    const Filters = new Filterclass()
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.Waiter = waiter?.value;
    Filters.Counter = counterval?.value;
    Filters.Section = sectionval?.value;
    Filters.SaleStatus = "Cancelled";
    
    try {
      const response = await getSalesStatusReport({ DatatablePagingParameters, Filters });
      console.log(response?.data);
      setsalesData(response?.data?.salesReportDetail)
      settotalcount(response?.data?.salesReportDetail[0]?.recordsFilteredCount)

    } catch (error) {
      console.error(
        "Error Fetching Account Type!",
        error?.response?.data?.Message
      );
    }
  }
  const handlegetsalesfullReport = async () => {
    setloading(true)
    const DatatablePagingParameters = new DatatablePagingParameter();
    DatatablePagingParameters.PageNumber = 1;
    DatatablePagingParameters.PageSize = -1;
    const formattedfromDate = dayjs(fromDate).format('YYYY-MM-DD');
    const formattedtoDate = dayjs(toDate).format('YYYY-MM-DD');
    const Filters = new Filterclass()
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.Waiter = waiter?.value;
    Filters.Counter = counterval?.value;
    Filters.Section = sectionval?.value;
    Filters.SaleStatus = "Cancelled";  
    try {
      const response = await getSalesStatusReport({ DatatablePagingParameters, Filters });
      setallData(response?.data?.salesReportDetail)
      setloading(false)

    } catch (error) {
      console.error(
        "Error Fetching Account Type!",
        error?.response?.data?.Message
      );
      setloading(false)
    }
  }
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const exportToPDF = () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const tableRef1 = document.getElementById('table-to-export');

    html2canvas(tableRef1).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 190; // Width in PDF
      const pageHeight = 295; // Height for A4
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
      let heightLeft = imgHeight;
      let position = 10;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('Sales_Report.pdf');
    });
  };

  // const exportToExcel = () => {
  //   const wb = XLSX.utils.book_new();
  //   const ws = XLSX.utils.json_to_sheet(salesData);  // Convert sales data to sheet
  //   XLSX.utils.book_append_sheet(wb, ws, "SalesReport");
  //   XLSX.writeFile(wb, "Sales_Report.xlsx");
  // };
  const printTable = () => {
    window.print();
  };

  const menuItems = [
    { value: "1", label: "Transaction Date", checked: true },
    { value: "2", label: "Section", checked: true },
    { value: "3", label: "Bill No", checked: true },
    { value: "4", label: "Amount", checked: true },
    { value: "5", label: "Bill Date", checked: true },
    { value: "6", label: "Bill Time", checked: true },
    { value: "7", label: "Counter", checked: true },
    { value: "8", label: "User", checked: true },
    { value: "9", label: "Reason", checked: true },
   
  ];

  const columns = [
    {
      title: "Transaction Date",
      key: "1",
      dataIndex: "transactionDate", 
      render: (text) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });
        };
    
        // Assuming text contains the transactionDate field
        return <span>{formatDate(text)}</span>;
      },

    },
    
    {
      title: "Section",
      key: "2",
      dataIndex: "section", 
      render: (text) => <span>{text.name}</span>,
    },

    {
      title: "Bill No",
      key: "3",
      dataIndex: "billNo",


    },
    {
      title: "Amount",
      key: "4",
      dataIndex: "netTotal",

    },
    {
      title: "Bill Date",
      key: "5",
      dataIndex: "billDate", 
      render: (text) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });
        };
    
        return <span>{formatDate(text)}</span>;
      },

    },
     {
          title: "Bill Time",
          key: "6",
          dataIndex: "billDate",
          render: (text) => {
            const date = new Date(text);
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            return `${hours}:${minutes}`;
          },
    
        },

    {
      title: "Counter",
      key: "7",
      dataIndex: "counter", 
      render: (text) => <span>{text.name}</span>,
    },
    {
      title: "User",
      key: "8",
      dataIndex: "user", 
      render: (text) => <span>{text.name}</span>,
    },
   
    {
      title: "Reason",
      key: "9",
      dataIndex:"reason"
    },

  ];

  const [selectedColumns, setSelectedColumns] = useState();

  useEffect(() => {
    // Set default selected columns based on menuItems' checked status
    const initialSelectedColumns = menuItems?.filter((item) => item.checked)?.map((item) => columns?.find((col) => col.key === item.value));

    setSelectedColumns(initialSelectedColumns);
  }, []);
  console.log(selectedColumns, "qqqqqq");

  const handleCheckboxChange = (columnKey, checked) => {
    if (checked) {
      // Add column if checkbox is checked
      setSelectedColumns(prevColumns => {
        const updatedColumns = [
          ...prevColumns,
          columns?.find(col => col.key === columnKey)
        ];

        // Sort the array by col.key in ascending order
        return updatedColumns.sort((a, b) => (a.key > b.key ? 1 : -1));
      });
    } else {
      // Remove column if checkbox is unchecked
      setSelectedColumns(prevColumns =>
        prevColumns?.filter(col => col.key !== columnKey)
      );
    }
  };

  

  const userroles = UserList?.map(e=>({
    value:e.guid,
    label:e.name
  }))
  const counter = counterList?.map(e=>({
    value:e.guid,
    label:e.name
  }))
  const section = sectionList?.map(e=>({
    value:e.guid,
    label:e.name
  }))

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleFromDateChange = (date) => {
    console.log(date, 'from date');
    setFromDate(date);


    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
    console.log(date, 'to date');
  };
  const getActiveTabData = () => {
    let fieldMapping = {};
  
    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };
  
    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);
    
    console.log("fieldMapping of first table:", fieldMapping);         
    console.log("salesData of  table:", allData);         
  
    // Return the filtered data, columns, and field mapping
    return { data: allData, columns: selectedColumns, fieldMapping };
  };
  const totalPrice = allData?.reduce((sum, record) => sum + record.netTotal, 0);
  return (
    <div>
      <LoadingUtils loading={loading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Cancelled Report</h4>

              </div>
            </div>

            
            <ul className="table-top-head">
              <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <button onClick={() => exportreportPDF(getActiveTabData)} disabled={loading} className="btn btn-link bg-white rounded-lg border border-silver" >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <button onClick={() => exportreportExcel(getActiveTabData)} disabled={loading} className="btn btn-link bg-white rounded-lg border border-silver">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="excel-icon"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <button onClick={() => printreportReport(getActiveTabData)} disabled={loading} className="btn btn-link bg-white rounded-lg border border-silver">
                    <i data-feather="printer" className="feather-printer" />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>

          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                      }`}
                    onClick={toggleFilterVisibility}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"

                    />
                    <span >
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>


                <div className="dropdown">
                  <button
                    className="btn  dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <Sliders className="info-img" />
                  </button>
                  <ul className="dropdown-menu p-2">
                    {menuItems?.map((obj, i) => {
                      return (
                        <li key={i} >
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(obj.value, e.target.checked)
                            }
                            defaultChecked={obj.checked}
                          >
                           <div style={{color:"black"}}> {obj.label}
                            </div>
                          </Checkbox>
                        </li>
                      );
                    })}
                  </ul>
                </div>

              </div>

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                      <DatePicker
                        className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          // selected={fromDate}
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          // dateFormat="dd/MM/yyyy"
                          
                        />
                          
                      </div>
                    </div>
                    </div>
                    
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                      <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          // selected={toDate}
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate} // disable until from date is selected
                          // dateFormat="dd/MM/yyyy"
                          // className='form-control'
                          
                        />
                      </div>
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i className="fas fa-money-bill info-img" />
                        <Select
                          className="img-select"
                          options={paymentcard}
                          value={paymenttype}
                          onChange={setPaymenttype}
                          classNamePrefix="react-select"
                          placeholder="Payment Type"
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />
                        <Select
                          className="img-select"
                          options={userroles}
                          value={waiter}
                          onChange={setwaiter}
                          classNamePrefix="react-select"
                          placeholder="Choose Waiter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Archive className="info-img" />
                        <Select
                          className="img-select"
                          options={counter}
                          value={counterval}
                          onChange={setcounterval}
                          classNamePrefix="react-select"
                          placeholder="Choose Counter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <File className="info-img" />
                        <Select
                          className="img-select"
                          options={section}
                          value={sectionval}
                          onChange={setsectionval}
                          classNamePrefix="react-select"
                          placeholder="Enter Section"
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto" onClick={ ()=>{
                            setCurrentPage(1);
                            setPageSize(20);
                            handlegetsalesfullReport()
                            handlegetsalesReport(currentPage,pageSize);}}
                        >
                          
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              {
                selectedColumns &&

                <div className="table-responsive product-list">
                  <Table columns={selectedColumns} dataSource={salesData} ref={tableRef} id="table-to-export" 
                  className="table datanew dataTable no-footer custom-table"
                    pagination={{
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalcount} items`,
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalcount, // Total records count
                    onChange: (page, size) => {
                      setCurrentPage(page);
                      setPageSize(size);
                      handlegetsalesReport(page,size)
                    },
                    showSizeChanger: true, // To enable page size selection dropdown
                    pageSizeOptions: ['10', '20', '50','100'], // Different page size options
                  }} 
                  summary={() => (
                    <Table.Summary.Row>
                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                    <Table.Summary.Cell>
                    <b>{totalPrice?.toFixed(3)}</b>
                    </Table.Summary.Cell>
                    </Table.Summary.Row>
                          )}
                  />

                </div>
              }
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};
export default CancelledSales;