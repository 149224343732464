import { DomainBase } from "./DomainBase";

export class BillReceiptDetail extends DomainBase {
    constructor() {
        super();
        this.MasterID = '';
        this.SalesMasterID = '';
        this.SalesReturnMasterID = '';
        this.Amount = 0;
        this.Discount = 0;
    }
}
