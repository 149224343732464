import { DomainBase } from "./DomainBase";
export class Branch extends DomainBase {
    constructor() {
        super();
        this.Name = '';
        this.Company = null;
        this.PrefixInvoice = '';
        this.Address1 = '';
        this.Address2 = '';
        this.Address3 = '';
        this.Phone = '';
        this.Email = '';
        this.CloneBranchId = '';
    }
}
export default Branch;