import axios from "axios";

const base_path = process.env.REACT_APP_API_URL;

//Accounts.......

export const getReceiptByGuid = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Receipt/GetAllByBranchID?globalBranchID=${branch.guid}`
  );
  return response.data;
};
export const addNewReceipt = async (receiptdata) => {
  console.log(receiptdata, "qqqqqq");

  const response = await axios.post(
    `${base_path}/Api/Receipt/SaveOrUpdate`,
    receiptdata
  );
  return response;
};
export const getPaymentByGuid = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Payment/GetAllByBranchID?globalBranchID=${branch.guid}`
  );
  return response.data;
};
export const addNewPayment = async (paymentdata) => {
  const response = await axios.post(
    `${base_path}/Api/Payment/SaveOrUpdate`,
    paymentdata
  );
  return response;
};
export const getAccountledger = async () => {
  const response = await axios.get(`${base_path}/Api/AccLedger/GetAll`);
  return response.data;
};
export const addAccountLedger = async (ledgerdata) => {
  console.log(ledgerdata, "ttttttt");

  const response = await axios.post(
    `${base_path}/Api/AccLedger/SaveOrUpdate`,
    ledgerdata
  );
  return response;
};

export const getAccountgroup = async () => {
  const response = await axios.get(`${base_path}/Api/AccGroup/GetAll`);
  return response.data;
};

export const addAccountgroup = async (groupdata) => {
  console.log(groupdata);

  const response = await axios.post(
    `${base_path}/Api/AccGroup/SaveOrUpdate`,
    groupdata
  );
  return response;
};
// ledger 
export const getcashorbank = async () => {
  const response = axios.get(
    `${base_path}/Api/AccLedger/GetCashBankLedgers`
  );
  return response;
};
// bill payment
export const getbillwisepayment = async (branch) => {
  const response = axios.get(
    `${base_path}/Api/BillPayment/GetAllByBranchID?globalBranchID=${branch?.guid}`
  );
  return response;
};

export const getsupBill = async (val,branch) => {
  const response = axios.get(
    `${base_path}/Api/BillPayment/GetAllSupBills?globalBranchID=${branch.guid}&supplierID=${val}`
  );
  return response;
};
export const savebillpayment = async (objbillpayment) => {
  const response = axios.post(
    `${base_path}/Api/BillPayment/SaveOrUpdate`,objbillpayment
  );
  return response;
};
export const deletebillpayment = async (data) => {
  const response = axios.post(
    `${base_path}/Api/BillPayment/Delete`,data
  );
  return response;
};
// receipt
export const getbillwisereceipt = async (branch) => {
  const response = axios.get(
    `${base_path}/Api/BillReceipt/GetAllByBranchID?globalBranchID=${branch?.guid}`
  );
  return response;
};

export const getcusBill = async (val,branch) => {
  const response = axios.get(
    `${base_path}/Api/BillReceipt/GetAllCustomerBills?globalBranchID=${branch.guid}&customerID=${val}`
  );
  return response;
};
export const savebillreceipt = async (objbillreceipt) => {
  const response = axios.post(
    `${base_path}/Api/BillReceipt/SaveOrUpdate`,objbillreceipt
  );
  return response;
};
export const deletebillreceipt = async (data) => {
  const response = axios.post(
    `${base_path}/Api/BillReceipt/Delete`,data
  );
  return response;
};
//journal get
export const getJournal = async () => {
  const response = await axios.get(`${base_path}/Api/Journal/GetAll`);
  return response;
};
//journal delete
export const deleteJournal = async (journalData) => {
  console.log(JSON.stringify(journalData));
  const response = await axios.post(
    `${base_path}/Api/Journal/Delete`,
    JSON.stringify(journalData)
  );
  console.log("deleted resoponse", response.data);
  return response.data;
};
export const savejournalentry = async (objjournal) => {
  const response = axios.post(
    `${base_path}/Api/Journal/SaveOrUpdate`,
    objjournal
  );
  return response;
};
