import { DomainBase } from "./DomainBase";

export  class ReceiptEntry extends DomainBase{ 
       constructor() {
        super();
        this.No = 0;     
        this.Date = new Date();  
        this.LedgerID = '';     
        this.Narration = ''; 
        this.IsCheque = false;  
        this.Currency = null;  
        this.CurrencyRate = 0; 
        this.TotalAmt =0;
        this.LedgerName=""
        this.Branch = null;   
        this.FinancialYear = null ;
        this.Company = null;      
        this.receiptDetails = [];  

     }}