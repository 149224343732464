import React, { useEffect, useState } from "react";
import Table from "../../../../core/pagination/datatable";
import { useDispatch, useSelector } from "react-redux";

function AddProductSection({ register, getValues, watch, setValue }) {
  const { sectionList, taxList } = useSelector((state) => state.usersetting);

  const columns = [
    {
      title: "Is Active",
      dataIndex: "",
      render: (_, value, index) => (
        <input
          type="checkbox"
          className=""
          {...register(`sectionWiseProductDetailList[${index}].IsActive`)}
        />
      ),
    },
    {
      title: "Section",
      dataIndex: "name",
      render: (_, value, index) => (
        <div>
          <select
            name=""
            id=""
            className="form-control"
            {...register(`sectionWiseProductDetailList[${index}].Section`)}
          >
            <option value={value.guid} key={value.id}>
              {value.name}
            </option>
          </select>
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "",
      render: (_, val, index) => (
        <div>
          <input
            type="number"
            className="form-control w-50"
            step="any"
            {...register(`sectionWiseProductDetailList[${index}].Price`)}
          />
        </div>
      ),
    },

    {
      title: "Tax",
      dataIndex: "",

      render: (_, value, index) => (
        <div>
          <select
            name=""
            id=""
            className="form-control"
            {...register(`sectionWiseProductDetailList[${index}].Tax`)}
            value={watch().sectionWiseProductDetailList[index].Tax}
            onChange={(e) =>
              setValue(
                `sectionWiseProductDetailList[${index}].Tax`,
                e.target.value
              )
            }
          >
            <option value=""> select</option>
            {taxList &&
              taxList?.map((obj) => {
                return (
                  <option value={obj.guid} key={obj.id}>
                    {obj.name}
                  </option>
                );
              })}
          </select>
        </div>
      ),
    },
    {
      title: "Tax Inclusive",
      dataIndex: "",
      render: (_, value, index) => {
        const taxSelected = watch(`sectionWiseProductDetailList[${index}].Tax`);
        return (
          <input
            type="checkbox"
            className=""
            {...register(
              `sectionWiseProductDetailList[${index}].IsTaxIncludedInPrice`
            )}
            disabled={!taxSelected} // Disable if no tax is selected
          />
        );
      },
    },
  ];

  return (
    <div>
      <div className="table-responsive product-list">
        <Table columns={columns} dataSource={sectionList} />
      </div>
    </div>
  );
}

export default AddProductSection;
