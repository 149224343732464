import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  RefreshCw,
  PlusCircle,
  RotateCcw,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import {
  getCurrencyList,
  getCurrencyByGuid,
  deleteCurrency,
  GetDenominations,
} from "../../services/MasterApiServices";
import CurrencyModal from "../../components/master/modal/CurrencyModal";
import AddCurrencDenom from "../../components/master/modal/AddCurrencDenom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import { Checkbox } from "antd";
import { exportMasterExcel, exportMasterPDF, printMasterReport } from "../../core/utils/MasterReportUtils";

const Currency = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [currencyData, setCurrencyData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [updateData, setUpdateData] = useState([]);
  const [denomData, setdenomData] = useState([]);
  const [currencyId, setcurrencyId] = useState();
  const { togglehead } = useSelector((state) => state.headerToggle);
  const {menuItems} = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  // Fetch the currency list when the component mounts
  useEffect(() => {
    handleCurrencyList();
    setpermit(menuItems?.menuPermissions?.find(e=> e.menu.name =="Currency"))
  }, []);

  // Fetch the currency lists
  const handleCurrencyList = async () => {
    try {
      const response = await getCurrencyList();

      if (Array.isArray(response)) {
        setCurrencyData(response);
      } else {
        setCurrencyData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message);
      setCurrencyData([]);
    }
  };

  // Handle the search
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = Array.isArray(currencyData)
    ? currencyData?.filter((value) =>
        value.name?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];

  // Handle the click event to edit a currency
  const handleEditClick = async (guid) => {
    try {
      console.log("inside editing");
      const response = await getCurrencyByGuid(guid);
      setUpdateData(response.data); // Set the data for editing
      setModalMode("edit"); // Set the modal mode to 'edit'
      setModalOpen(true);
      console.log(response.data, "updatedata"); // Open the modal
    } catch (error) {
      console.error(
        "Error fetching currency by GUID:",
        error?.response?.data?.Message
      );
    }
  };

  // Initialize SweetAlert with ReactContent
  const MySwal = withReactContent(Swal);

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  // Handle the click event to delete a currency
  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteCurrency(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The currency has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handleCurrencyList(); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting currency:",
            error?.response?.data?.Message
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const handlecurrencyId=(id)=>{
    if(currencyId==id){
      setcurrencyId()
      setUpdateData()
    }else{
  
      setcurrencyId(id)
      setUpdateData(currencyData?.find(e=>e.guid==id))
    }
   }  
  const columns = [
    {
      title: "",
      
      render: (_, obj) => (
        <div className="action-table-data">
          <Checkbox onChange={()=>handlecurrencyId(obj.guid)} checked={obj.guid==currencyId} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: (a, b) => a.unit?.length - b.unit?.length,
    },
    {
      title: "Sub Unit",
      dataIndex: "subUnit",
      sorter: (a, b) => a.subUnit?.length - b.subUnit?.length,
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (active) => (active ? "Yes" : "No"),
      sorter: (a, b) => a.active - b.active,
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj.id)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : [])
  ];

  const handlegetdenome=async()=>{
    // const objcurrencydetail = new CurrencyDenomination()
    // const objcurrency = new CurrencyDenominationAssignment()
    try {
      const response = await GetDenominations(updateData);
      console.log(response);
      setdenomData(response)
    } catch (error) {
      console.error(
        "Error fetching currency by GUID:",
        error?.response?.data?.Message
      );
    }
  }
  const getActiveTabData = () => {
    let fieldMapping = {};
  
    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };
  
    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);
    
    // Return the filtered data, columns, and field mapping
    return { data: modifiedList, columns: columns, fieldMapping };
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Currencies</h4>
                <h6>Manage currencies here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() => exportMasterPDF(getActiveTabData,"Currency")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    o  onClick={() => exportMasterExcel(getActiveTabData,"Currency")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => printMasterReport(getActiveTabData,"Currency")}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleCurrencyList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>

            <div className="page-btn">
              <button
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#denomination"
                disabled={!currencyId}
                onClick={handlegetdenome}
              >
                <PlusCircle className="me-2" />
                Add Dinomination
              </button>
            </div>

            {permit?.add && 
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>}
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to="#" className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Currency Modal */}
      {modalOpen && (
        <CurrencyModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handleCurrencyList(); // Refresh the list
            setSearchText(""); // Clear the search text
          }}
        />
      )}
      <AddCurrencDenom data={denomData} Currency={updateData} />
     
    </div>
  );
};

export default Currency;
