import { DomainBase } from "./DomainBase";

export class Currency extends DomainBase{
    constructor() {
        super();
        this.name = '';
        this.rate = 0;
        this.unit = '';
        this.subUnit = '';
        this.active = false;
    }
}