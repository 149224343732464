import { DomainBase } from "./DomainBase";

export class BillPaymentDetail extends DomainBase {
    constructor() {
        super();  // Call the parent class constructor
        this.MasterID = '';
        this.PurchaseMasterID = '';
        this.PurchaseReturnMasterID = '';
        this.Amount = 0;
        this.Discount = 0;
        this.TransType = '';
        this.InvoiceNO = '';
        this.EntryNumber = '';
        this.EntryDate = new Date();
        this.NetTotal = 0;
    }
}
