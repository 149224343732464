import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImportPurchases from "../../core/modals/purchases/importpurchases";
import EditPurchases from "../../core/modals/purchases/editpurchases";
import Table from "../../core/pagination/datatable";
import AddPurchases from "../../core/modals/purchases/addpurchases";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";	
import {
  RotateCcw,
  ChevronUp,
  PlusCircle,
 } from "feather-icons-react/build/IconComponents";
import { fetchType } from "../../redux/userSetting";
import { exportExcel, exportPDF, HandlePrint, renderTooltip } from "../../redux/helper";
import { exportMasterExcel, exportMasterPDF, printMasterReport } from "../../core/utils/MasterReportUtils";


const Type = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { deliveyTypes } = useSelector((state) => state.usersetting);
  const {menuItems} = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead))
  }

    useEffect(() => {
      setpermit(menuItems?.menuPermissions?.find(e=> e.menu.name =="Type"))
      dispatch(fetchType())
    }, [])
    const handleSearch = (e) => {
      setSearchText(e.target.value);
      
    };
   
  
     
   
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => a.name?.length - b.name?.length,
      },
    ];
    const getActiveTabData = () => {
      let fieldMapping = {};
    
      // Function to create the field mapping between column titles and dataIndex values
      const createfieldMapping = (columns) => {
        const mappings = {};
        columns.forEach((col) => {
          mappings[col.title] = col.dataIndex; // Map title to dataIndex
        });
        return mappings;
      };
    
      // Generate the field mapping for the current columns
      fieldMapping = createfieldMapping(columns);
      
      // Return the filtered data, columns, and field mapping
      return { data: deliveyTypes, columns: columns, fieldMapping };
    };
return (
      <div>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header transfer">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Type</h4>
                </div>
              </div>
              <ul className="table-top-head">
              <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                <Link onClick={() => exportMasterPDF(getActiveTabData,"Type")}>
                  <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("Excel")}>
                <Link
                  onClick={() => exportMasterExcel(getActiveTabData,"Type")}
                >
                  <ImageWithBasePath
                    src="assets/img/icons/excel.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("Print")}>
                <Link
                 onClick={() => printMasterReport(getActiveTabData,"Type")}
                >
                  <i data-feather="printer" className="feather-printer" />
                </Link>
              </OverlayTrigger>
            </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip('Refresh')}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top"
                  onClick={()=>dispatch(fetchType())}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip('Collapse')}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
              <ul className="table-top-head">
                
               
               
                
              </ul>
              {/* <div className="d-flex purchase-pg-btn">
                <div className="page-btn">
                  <Link
                    to="#"
                    className="btn btn-added"
                    data-bs-toggle="modal"
                    data-bs-target="#add-units"
                  >
                    <PlusCircle className="me-2" />
                    Add New
                  </Link>
                </div>
                <div className="page-btn import">
                  <Link
                    to="#"
                    className="btn btn-added color"
                    data-bs-toggle="modal"
                    data-bs-target="#view-notes"
                  >
                    <RefreshCw className="me-2" />
                    Refresh
                  </Link>
                </div>
              </div> */}
            </div>
            {/* /product list */}
            <div className="card table-list-card">
              <div className="card-body">
                <div className="table-top">
                <div className="search-set">
                                <div className="search-input">
                                  <Link to="#" className="btn btn-searchset">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-search"
                                    >
                                      <circle cx={11} cy={11} r={8} />
                                      <line
                                        x1={21}
                                        y1={21}
                                        x2="16.65"
                                        y2="16.65"
                                      />
                                    </svg>
                                  </Link>
                                  <div
                                    id="DataTables_Table_0_filter"
                                    className="dataTables_filter"
                                  >
                                    <label>
                                      {" "}
                                      <input
                                        type="search"
                                        className="form-control form-control-sm"
                                        placeholder="Search"
                                        aria-controls="DataTables_Table_0"
                                        value={searchText}
                                        onChange={handleSearch}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                  
                </div>
               
                <div className="table-responsive product-list">
                  <Table columns={columns} dataSource={deliveyTypes} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddPurchases />
        <ImportPurchases />
        <EditPurchases />
      </div>
    );
}

export default Type
